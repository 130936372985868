import { useState } from "react";
import { Link } from "react-router-dom";

function Nav() {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };
  const close = () => {
    setIsOpen(false);
  };

  return (
    <nav className="nav_padding">
      <div
        className="nav_container"
        data-aos="fade-down"
        data-aos-duration="1000"
      >
        <div className="nav_home ">
          <Link
            onClick={close}
            to={"/"}
            data-aos="fade-down"
            data-aos-duration="3000"
            data-aos-delay="400"
          >
            Graphic & Web Designer
          </Link>
        </div>
        <div className="nav_items_d">
          <ul>
            <li>
              <Link
                to={"/Work"}
                data-aos="fade-down"
                data-aos-duration="3000"
                data-aos-delay="400"
              >
                Portfolio
              </Link>
            </li>
            <li>,</li>
            <li>
              <Link
                to={"/Who"}
                data-aos="fade-down"
                data-aos-duration="3000"
                data-aos-delay="600"
              >
                About
              </Link>
            </li>
            <li>,</li>
            <li>
              <Link
                to={"/Contact"}
                data-aos="fade-down"
                data-aos-duration="3000"
                data-aos-delay="800"
              >
                Contact
              </Link>
            </li>
          </ul>
        </div>
        {isOpen && (
          <div className={`nav_items ${isOpen ? "active" : "inactive"}`}>
            <ul>
              <li>
                <Link
                  onClick={toggle}
                  to={"/Work"}
                  data-aos="fade-down"
                  data-aos-duration="1000"
                  data-aos-delay="200"
                >
                  Portfolio
                </Link>
              </li>

              <li>
                <Link
                  onClick={toggle}
                  to={"/Who"}
                  data-aos="fade-down"
                  data-aos-duration="1000"
                  data-aos-delay="400"
                >
                  About
                </Link>
              </li>
              <li>
                <Link
                  onClick={toggle}
                  to={"/Contact"}
                  data-aos="fade-down"
                  data-aos-duration="1000"
                  data-aos-delay="600"
                >
                  Contact
                </Link>
              </li>
            </ul>
          </div>
        )}
        <div className="nav_menu_icon" onClick={() => setIsOpen(!isOpen)}>
          {isOpen ? "Close" : "Menu"}
        </div>
      </div>
    </nav>
  );
}

export default Nav;
