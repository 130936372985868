// dependencies
import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

// components
import Home from "../pages/home/Home";
import Work from "../pages/work/Work";
import WorkItem from "../pages/work/workItem/WorkItem";
import Who from "../pages/who/Who";
import Contact from "../pages/contact/Contact";

const AnimatedRoutes = () => {
  const location = useLocation();
  return (
    <AnimatePresence
      mode="wait"
      onExitComplete={() => {
        if (typeof window !== "undefined") {
          window.scrollTo({ top: 0, behavior: "instant" });
        }
      }}
    >
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<Home />}></Route>
        <Route path="/Work" element={<Work />}></Route>
        <Route path="/Work/:id" element={<WorkItem />}></Route>
        <Route path="/Who" element={<Who />}></Route>
        <Route path="/Contact" element={<Contact />}></Route>
      </Routes>
    </AnimatePresence>
  );
};

export default AnimatedRoutes;
