import React from "react";

const WhoTools = () => {
  return (
    <div className="who_tools_container section_padding">
      <div className="section_marker">
        <div className="marker_number">
          <p data-aos="fade-up" data-aos-duration="500" data-aos-offset="0">
            02 | 02
          </p>
        </div>
        <div className="marker_header">
          <p data-aos="fade-up" data-aos-duration="500" data-aos-offset="0">
            My Tools
          </p>
        </div>
      </div>
      <div className="who_t_content">
        <p data-aos="fade-up" data-aos-duration="500">
          Graphic Design, Branding, Logo Design, Package Design, Asset Creation,
          Web Design, UX/UI Design, Web Development, React-Press, Framer-motion
        </p>
      </div>
    </div>
  );
};

export default WhoTools;
