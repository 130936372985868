import React from "react";

const Footer = () => {
  return (
    <footer>
      <div className="footer_item fm_r">
        <p>FIJI, KDV</p>
      </div>
      <div className="footer_item">
        <p>By Joji, 2024</p>
      </div>
      <div className="footer_item">
        <p>Vuku Creative Studios</p>
      </div>
    </footer>
  );
};

export default Footer;
