import React from "react";

// import image
import fpImage1 from "../../media/images/KMP_img.jpg";
import fpImage2 from "../../media/images/Asset_18.svg";
import fpImage3 from "../../media/images/FreshMoniAsset.jpg";

const fpArray = [
  {
    no: "01",
    title: "Kahungunu Mataariki Project",
    year: "2023",
    category: { a: "Web Design", b: "Web Development", c: "UX / UI" },
    image: fpImage1,
    info: "Kahungunu Mataariki Project is Māori led initiative that aims at educating Kahungunu hapū on various traditional kai, ceremony and wellbeing. I designed and built their website to allow them to host and store all the images, thoughts and feedback from each Wanānga.",
    website: "https://www.kahungunumataarikiproject.com/",
  },
  {
    no: "02",
    title: "Sesa Mathlo Apothecary",
    year: "2024",
    category: { a: "Package Design", b: "Graphic Design", c: "Branding" },
    image: fpImage2,
    info: "Sesa Mathlo Apothecary takes a holistic approach to care, well-being and purpose. I created their package design and branding for their golden elixir facial oil product.",
    website:
      "https://www.sesamathloapothecary.online/shop/p/golden-elixir-facial-oil",
  },
  {
    no: "03",
    title: "Fresh Moni Podcast",
    year: "2024",
    category: { a: "Web Design", b: "Web Development", c: "UX / UI" },
    image: fpImage3,
    info: "Fresh Moni Podcast is a financial advisory podcast that focus on personal finance, business, investments, or navigating financial challenges. I designed and built the one-page website as a quick and easy resource that my client can use to provide information on him and his podcast.",
    website: "https://freshmoni.co.nz/",
  },
];

const fpArrayDataItems = fpArray.map((fpArray) => (
  <a href={fpArray.website} className="fp_block_item  " key={fpArray.id}>
    <div className="fp_marker ">
      {/* <div
        className="fp_marker_no"
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="200"
      >
        <p>{fpArray.no}</p>
      </div> */}
      <div
        className="fp_marker_title"
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="100"
      >
        <p>{fpArray.title}</p>
      </div>
      <div
        className="fp_marker_year"
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="100"
      >
        <p>{fpArray.year}</p>
      </div>

      {/* <div className="fp_marker_category">
        <div
          className="fpc"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="500"
        >
          <p>{fpArray.category.a}</p>
        </div>
        <div
          className="fpc"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="600"
        >
          <p>{fpArray.category.b}</p>
        </div>
        <div
          className="fpc"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="700"
        >
          <p>{fpArray.category.c}</p>
        </div>
      </div> */}
    </div>
    <div className="fp_content">
      <div
        className="fp_content_image"
        style={{ backgroundImage: `url(${fpArray.image}` }}
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="300"
      ></div>
    </div>
  </a>
));

const HomeFeaturedProducts = () => {
  return (
    <div className="featured_products_container section_padding">
      <div className="section_marker">
        <div
          className="marker_number"
          data-aos="fade-up"
          data-aos-duration="500"
          data-aos-offset="0"
        >
          <p>02 | 02</p>
        </div>
        <div
          className="marker_header"
          data-aos="fade-up"
          data-aos-duration="500"
          data-aos-offset="0"
        >
          <p>Recent Projects</p>
        </div>
      </div>
      <div className="fp_block">{fpArrayDataItems}</div>
    </div>
  );
};

export default HomeFeaturedProducts;
