import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowTrendUp } from "@fortawesome/free-solid-svg-icons";

const HomeContact = () => {
  return (
    <div className="home_contact_container section_padding">
      <div className="section_marker">
        <div className="marker_number">
          <p>Contact</p>
        </div>
        <div className="marker_header">
          <p>Get In Touch</p>
        </div>
      </div>
      <div className="hm_contact_content_block ">
        <div className="hm_contact_mail ">
          <div
            className="hm_mail_title"
            data-aos="fade-in"
            data-aos-duration="1000"
          >
            <p data-aos="fade-up" data-aos-duration="1000" data-aos-delay="500">
              Yo! Need help with a project
            </p>
          </div>
          <div className="hm_mail_address ">
            <p
              data-aos="fade-right"
              data-aos-duration="1000"
              data-aos-delay="800"
            >
              jratuva@gmail.com
            </p>
          </div>
        </div>
        <div className="hm_contact_sm ">
          <div
            className="hm_sm_title"
            data-aos="fade-in"
            data-aos-duration="1000"
          >
            <p data-aos="fade-up" data-aos-duration="1000" data-aos-delay="500">
              Links and That
            </p>
          </div>
          <div className="hm_sm_links">
            <a
              href="https://www.linkedin.com/in/joji-ratuva-ab9726172/"
              className="hm_contact_sm_link "
              data-aos="fade-right"
              data-aos-duration="1000"
              data-aos-delay="300"
            >
              <div className="sm_name">
                <p>Linkedin</p>
              </div>
              <div className="sm_icon">
                <FontAwesomeIcon icon={faArrowTrendUp} size="1x" />
              </div>
            </a>
            <a
              href="https://www.instagram.com/mordorwinterknights/?hl=en"
              className="hm_contact_sm_link "
              data-aos="fade-right"
              data-aos-duration="1000"
              data-aos-delay="500"
            >
              <div className="sm_name">
                <p>Instagram</p>
              </div>
              <div className="sm_icon">
                <FontAwesomeIcon icon={faArrowTrendUp} size="1x" />
              </div>
            </a>
            {/* <Link
              className="hm_contact_sm_link "
              data-aos="fade-right"
              data-aos-duration="1000"
              data-aos-delay="700"
            >
              <div className="sm_name">
                <p>Behance</p>
              </div>
              <div className="sm_icon">
                <FontAwesomeIcon icon={faArrowTrendUp} size="1x" />
              </div>
            </Link> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeContact;
