import React from "react";
import HomeContact from "../home/HomeContact";

const Contact = () => {
  return (
    <>
      <HomeContact />
    </>
  );
};

export default Contact;
