// dependencies
import { BrowserRouter as Router } from "react-router-dom";
//components
import AnimatedRoutes from "./components/AnimatedRoutes";
//components/constant
import Nav from "./components/constant/Nav";
import Footer from "./components/constant/Footer";

// style
import "./style/style.css";

function App() {
  return (
    <Router>
      <Nav />
      <AnimatedRoutes />
      <Footer />
    </Router>
  );
}

export default App;
