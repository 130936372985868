import React from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faArrowTrendUp } from "@fortawesome/free-solid-svg-icons";
// import { motion } from "framer-motion";

// Base URL
// const baseURL = process.env.REACT_APP_WP_API_BASEURL;
const baseURL = "https://jojiratuva.com/portfoliohost/wp-json/wp/v2/";
// console.log(baseURL);
// Cache refresh
const cacheRefresh = `&timestamp=${new Date().getTime()}`;
// Main end point
const endPoint = `${baseURL}posts?per_page=40&_embed${cacheRefresh}`;
// axios GET
const WorkGalleryContainer = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [error, setError] = useState("");
  useEffect(() => {
    axios
      .get(endPoint)
      .then((res) => {
        setData(res.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setError(error.message);
        setIsLoading(false);
      });
  }, []);
  // console.log(data);
  // Catch for loading message
  if (isLoading) {
    return (
      <div
        style={{
          width: "100%",
          height: "91vh",
          fontSize: "1vw",
          letterSpacing: ".5vw",
          textTransform: "uppercase",
          fontWeight: 500,
        }}
      >
        <p>. . .</p>
      </div>
    );
  }
  // Catch for error message
  if (error) {
    return (
      <div
        style={{
          width: "100%",
          height: "91vh",
          display: "flex",
          letterSpacing: ".5vw",
          textTransform: "uppercase",
          fontWeight: 500,
        }}
      >
        <h1>Error: {error}</h1>
      </div>
    );
  }

  const galleryPost = () => {
    return (
      <>
        {data.map((item) => {
          return (
            <Link
              className="work_gallery_post"
              key={item.id}
              to={`/work/${item.id}`}
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-offset="0"
            >
              <div
                className="post_image"
                style={{
                  backgroundImage: `url(${item._embedded["wp:featuredmedia"][0].source_url})`,
                }}
              >
                <div className="post_image_content">
                  <p>{item.title.rendered}</p>
                </div>
              </div>
            </Link>
          );
        })}
      </>
    );
  };
  return galleryPost();
};

const WorkHeader = () => {
  return (
    <div className="work_gallery_header">
      <h1 data-aos="fade-down" data-aos-duration="1000" data-aos-offset="0">
        Portfolio
      </h1>
    </div>
  );
};

const WorkGallery = () => {
  return (
    <section className="work_gallery_main section_padding">
      <WorkHeader />
      <div className="work_gallery_container">
        <WorkGalleryContainer />
      </div>
    </section>
  );
};

export default WorkGallery;
