import React from "react";
import whoImage from "../../media/images/home_image.jpg";

const WhoMain = () => {
  return (
    <div className="who_main ">
      <div className="who_m_container ">
        <div className="who_m_header ">
          <p
            data-aos="fade-right"
            data-aos-duration="1000"
            data-aos-delay="500"
          >
            Joji Ratuva
          </p>
        </div>
        <div className="who_m_tools ">
          <div
            className="m_tools_item"
            data-aos="fade-right"
            data-aos-duration="1000"
            data-aos-delay="700"
          >
            <p>Graphic Design</p>
          </div>
          <div
            className="m_tools_item"
            data-aos="fade-right"
            data-aos-duration="1000"
            data-aos-delay="800"
          >
            <p>Web & UX/UI Design</p>
          </div>
          <div
            className="m_tools_item"
            data-aos="fade-right"
            data-aos-duration="1000"
            data-aos-delay="900"
          >
            <p>Web Development</p>
          </div>
          <div
            className="m_tools_item"
            data-aos="fade-right"
            data-aos-duration="1000"
            data-aos-delay="1000"
          >
            <p>Packaging & Branding</p>
          </div>
        </div>
        <div
          className="who_m_image"
          style={{ backgroundImage: `url(${whoImage})` }}
          data-aos="fade-left"
          data-aos-duration="500"
        ></div>
      </div>
    </div>
  );
};

export default WhoMain;
