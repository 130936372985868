import React from "react";
import HomeContact from "../home/HomeContact";
import WorkGallery from "./WorkGallery";

const Work = () => {
  return (
    <>
      <WorkGallery />
      <HomeContact />
    </>
  );
};

export default Work;
