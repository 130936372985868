import React from "react";
// components
import WhoMain from "./WhoMain";
import WhoBio from "./WhoBio";
import WhoTools from "./WhoTools";
import HomeContact from "../home/HomeContact";
const Who = () => {
  return (
    <>
      <WhoMain />
      <WhoBio />
      <WhoTools />
      <HomeContact />
    </>
  );
};

export default Who;
