import React from "react";
import axios from "axios";
import { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import HomeContact from "../../home/HomeContact";
import StickyBox from "react-sticky-box";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

const baseURL = "https://jojiratuva.com/portfoliohost/wp-json/wp/v2/";
// console.log(baseURL);
// Cache refresh
const cacheRefresh = `&timestamp=${new Date().getTime()}`;
// Main end point
// axios GET

const WorkItem = () => {
  const params = useParams();
  const navigate = useNavigate();
  const endPoint = `${baseURL}posts/${params.id}?_embed${cacheRefresh}`;
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [error, setError] = useState("");
  // console.log(data);

  useEffect(() => {
    axios
      .get(endPoint)
      .then((res) => {
        setData(res.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setError(error.message);
        setIsLoading(false);
      });
  }, [endPoint]);
  // Catch for loading message
  if (isLoading) {
    return (
      <div
        style={{
          width: "100%",
          height: "91vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "1vw",
          letterSpacing: ".5vw",
          textTransform: "uppercase",
          fontWeight: 500,
        }}
      >
        <p>Loading . . .</p>
      </div>
    );
  }
  // Catch for error message
  if (error) {
    return (
      <div
        style={{
          width: "100%",
          height: "91vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "1vw",
          letterSpacing: ".5vw",
          textTransform: "uppercase",
          fontWeight: 500,
        }}
      >
        <h1>Error: {error}</h1>
      </div>
    );
  }
  //
  const WorkItemGallery = () => {
    const galleryArray = [
      {
        id: 1,
        image: data.acf.image_1,
        alt: "image",
        data_style: "fade-down",
        data_duration: "1500",
        data_delay: "800",
      },
      {
        id: 2,
        image: data.acf.image_2,
        alt: "image",
        data_style: "fade-up",
        data_duration: "1000",
        data_delay: "100",
      },
      {
        id: 3,
        image: data.acf.image_3,
        alt: "image",
        data_style: "fade-up",
        data_duration: "1000",
        data_delay: "100",
      },
      {
        id: 4,
        image: data.acf.image_4,
        alt: "image",
        data_style: "fade-up",
        data_duration: "1000",
        data_delay: "100",
      },
      {
        id: 5,
        image: data.acf.image_5,
        alt: "image",
        data_style: "fade-up",
        data_duration: "1000",
        data_delay: "100",
      },
    ];
    return galleryArray.map((item, index) => {
      if (item.image === "") {
        return "";
      } else {
        return (
          <img
            src={item.image}
            alt={item.alt}
            key={index}
            data-aos={item.data_style}
            data-aos-duration={item.duration}
            data-aos-delay={item.delay}
            data-aos-offset="0"
          />
        );
      }
    });
  };
  const WorkItemLink = () => {
    if (data.acf.url === "") {
      return "";
    } else {
      return (
        <div
          className="wi_content_link"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="1000"
          data-aos-offset="0"
        >
          <a href={data.acf.url}>Explore</a>
        </div>
      );
    }
  };
  const WorkItemContent = () => {
    return (
      <>
        <section className="work_item_container">
          <div className="work_item_sidebar">
            <StickyBox offsetTop={60} offsetBottom={60}>
              <div className="work_item_sidebar-item ">
                <div
                  className="wi_back_btn  "
                  onClick={() => {
                    navigate(-1);
                  }}
                  data-aos="fade-in"
                  data-aos-duration="1000"
                  data-aos-delay="1000"
                  data-aos-offset="100"
                >
                  <FontAwesomeIcon icon={faArrowLeft} size="2x" />
                </div>
                <div className="wi_content_header">
                  <h1
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-delay="500"
                    data-aos-offset="100"
                  >
                    {data.title.rendered}
                  </h1>
                </div>
                <div
                  className="wi_content_category"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="600"
                >
                  <p>{data.acf.category_a}</p>
                  <p>{data.acf.category_b}</p>
                  <p>{data.acf.category_c}</p>
                </div>
                <div
                  className="wi_content_info"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="800"
                >
                  <p>{data.acf.info}</p>
                </div>
                <WorkItemLink />
              </div>
            </StickyBox>
          </div>
          <div className="work_item_content">
            <WorkItemGallery />
          </div>
        </section>
      </>
    );
  };

  return (
    <>
      <div className="work_item_main section_padding">
        <WorkItemContent />
      </div>
      <HomeContact />
    </>
  );
};

export default WorkItem;
