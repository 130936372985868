import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import homeImage from "../../media/images/home_image.jpg";

const HomeMain = () => {
  return (
    // for flex
    <section className="home_container section_padding ">
      <div className="home_name_title ">
        <div className="title_name_first">
          <h2 data-aos="fade-up" data-aos-duration="2000" data-aos-delay="200">
            Joji
          </h2>
        </div>
        <div className="title_name_last">
          <h1 data-aos="fade-up" data-aos-duration="2000" data-aos-delay="600">
            Ratuva
          </h1>
        </div>
      </div>
      <div className="home_items " data-aos="fade-in" data-aos-duration="500">
        <div
          className="home_item1 "
          data-aos="fade-down"
          data-aos-duration="2000"
          data-aos-delay="800"
        >
          <p>mail</p>
          <p>jratuva@gmail.com</p>
        </div>
        <div
          className="home_item2 "
          data-aos="fade-down"
          data-aos-duration="2000"
          data-aos-delay="1000"
        >
          <p>Fijian Born</p>
          <p>NZ, Wairarapa Based</p>
        </div>
        <div
          className="home_item3 "
          data-aos="fade-down"
          data-aos-duration="2000"
          data-aos-delay="1200"
          data-aos-offset="0"
        >
          <div className="item3_a ">
            <p>Digital Creator</p>
            <p>Graphic & Web Designer</p>
          </div>
          <div className="item3_b ">
            <p>Freelance</p>
          </div>
        </div>
        <div
          className="home_item4 "
          data-aos="fade-down"
          data-aos-duration="2000"
          data-aos-delay="1400"
        >
          <p>
            Every Project is an exciting opportunity to express creativitiy in
            Design
          </p>
        </div>
        <div
          className="home_item5"
          data-aos="fade-down"
          data-aos-duration="2000"
          data-aos-delay="1600"
          data-aos-offset="0"
        >
          <div className="item5_a">
            <FontAwesomeIcon icon={faArrowDown} size="2x" />
          </div>
        </div>
      </div>
      <div
        className="home_image"
        style={{ backgroundImage: `url(${homeImage})` }}
        data-aos="flip-right"
        data-aos-duration="3000"
      ></div>
    </section>
  );
};

export default HomeMain;
