import React from "react";
// Components
import HomeMain from "./HomeMain";
import HomeBio from "./HomeBio";
import HomeFeaturedProducts from "./HomeFeaturedProducts";
import HomeContact from "./HomeContact";
const Home = () => {
  return (
    <div className="">
      <HomeMain />
      <HomeBio />
      <HomeFeaturedProducts />
      <HomeContact />
    </div>
  );
};

export default Home;
