import React from "react";

const WhoBio = () => {
  return (
    <div className="who_bio_container section_padding">
      <div className="section_marker">
        <div className="marker_number">
          <p data-aos="fade-up" data-aos-duration="500" data-aos-offset="0">
            01 | 02
          </p>
        </div>
        <div className="marker_header">
          <p data-aos="fade-up" data-aos-duration="500" data-aos-offset="0">
            Get to know my story
          </p>
        </div>
      </div>
      <div className="who_b_content ">
        <p>
          <span
            className="wbc_line"
            data-aos="fade-in"
            data-aos-duration="500"
            data-aos-delay="200"
            data-aos-offset="100"
          >
            Kia ora & Bula! My name is Joji Ratuva and I am a freelance Graphic
            & Web Designer.
          </span>
          <span
            className="wbc_line"
            data-aos="fade-in"
            data-aos-duration="500"
            data-aos-delay="200"
            data-aos-offset="100"
          >
            I was Born and raised in Fiji, whilst also living in different parts
            of the world as a child built a strong sense of resilience and
            adaptability.
          </span>
          <span
            className="wbc_line"
            data-aos="fade-in"
            data-aos-duration="500"
            data-aos-delay="200"
            data-aos-offset="100"
          >
            After completing my degeree in Economics and Political Science, I
            later found myself working prominently within the NZ logistics
            industry.
          </span>
          <span
            className="wbc_line"
            data-aos="fade-in"
            data-aos-duration="500"
            data-aos-delay="200"
            data-aos-offset="100"
          >
            After 6 years, I decided to pursue my creative calling and study
            graphic, web and UX design full-time at Yoobee College of Creative
            Innovation in Pōneke, Wellington.
          </span>
          <span
            className="wbc_line"
            data-aos="fade-in"
            data-aos-duration="500"
            data-aos-delay="200"
            data-aos-offset="100"
          >
            Since graduating, I have established myself as an innovative
            upcoming digital designer in the region and within my Pasifika
            community.
          </span>
          <span
            className="wbc_line"
            data-aos="fade-in "
            data-aos-duration="500"
            data-aos-delay="200"
            data-aos-offset="100"
          >
            I am passionate about integrating traditional iTaukei (fijian)
            design into different forms of storytelling; protecting and
            uplifting indigenous data; and creating intentional connections with
            collaborators.
          </span>
          <span
            className="wbc_line"
            data-aos="fade-in "
            data-aos-duration="500"
            data-aos-delay="200"
            data-aos-offset="100"
          >
            I live in Pai tū Mōkai, Wairarapa with my wife, son and newborn
            daughter. I love spending time with my family and taking in, all
            that the Wairarapa has to offer.
          </span>
        </p>
      </div>
    </div>
  );
};

export default WhoBio;
