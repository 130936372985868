import React from "react";
import { Link } from "react-router-dom";

const HomeBio = () => {
  return (
    <div className="bio_container section_padding">
      <div className="section_marker">
        <div className="marker_number">
          <p data-aos="fade-up" data-aos-duration="500" data-aos-offset="0">
            01 | 02
          </p>
        </div>
        <div className="marker_header">
          <p data-aos="fade-up" data-aos-duration="500" data-aos-offset="0">
            Nau Mai, Haere Mai
          </p>
        </div>
      </div>
      <div className="bio_content ">
        <div className="bio_text ">
          <p data-aos="fade-up" data-aos-duration="3000">
            Kia ora & Bula! My name is Joji Ratuva and I am a freelance Graphic
            & Web Designer
          </p>
        </div>
        <div className="bio_btn_container ">
          <div
            className="bio_btn "
            data-aos="flip-right"
            data-aos-duration="1000"
            data-aos-offset="100"
          >
            <Link to={"/Who"}>Learn More About Me</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeBio;
